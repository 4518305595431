import React, { useState } from "react";
import "../../styles/featuredProduct/featuredProduct.css";
import Products from "./Products";
import element from '../../assets/PNG/ELEMNTS-17.png'
const FeaturedProduct = () => {
  const initialLanguage = sessionStorage.getItem("lang") || "en";

  const content = {
    en: {
      title: "Featured Products",
      subtitle: (
        <>
          Discover our curated selection of curly hair essentials - from
          <br />
          Activators to Leave-in Conditioners, each designed to nourish, define,
          and celebrate your natural curls.
        </>
      )
    },
    ar: {
      title: "المنتج المميز",
      subtitle: (
        <div dir="rtl" lang="ar">
          اكتشفي مجموعتنا المنسقة من أساسيات الشعر المجعد - من
          <br />
          منشطات لمكيفات تترك على الشعر ، كل منها مصمم لتغذية تجعيد الشعر الطبيعي وتحديده والاحتفاء به.
        </div>
      )
    }
  };

  // Use state to track selected language
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);

  const filter = [
    { name: "skinCar", id: "1" },
    { name: "skin", id: "2" },
    { name: "skin", id: "3" },
    { name: "skinCar", id: "4" },
    { name: "skin", id: "5" },
  ];
  const [selectedFilter, setSelectedFilter] = useState("");
  return (
    <>
      <div style={{ marginTop: "3rem", marginBottom: "0rem" }}>
        <div className="featured-product ">
          <div className="content mb-2">
            <div className="title pe-md-0 pe-3"><img src={element} style={{ width: "55px" }} alt="" />{content[selectedLanguage].title}</div>
            <div className="subtitle">
              {content[selectedLanguage].subtitle}
            </div>
          </div>
        </div>

        <Products />
      </div>
    </>
  );
};

export default FeaturedProduct;
