import React from 'react';
import { Box, Typography, Button, Container, Paper, Divider, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate, useLocation } from 'react-router-dom';
import Banner from '../components/banner/Banner';

const OrderConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const orderData = location.state; // Get orderData from the location state

  // Optional: Add a fallback if orderData is not found
  if (!orderData || !orderData.order) {
    return <Typography variant="h6">Order data not available.</Typography>;
  }

  const { firstName, lastName, email, phone, address, delivery, products, total } = orderData.order[0];
  const totalProductPrice = products.reduce(
    (acc, item) => acc + item.quantity * item.product.variant.price,
    0
  );
  
  // Calculate the overall total price (products + delivery)
  const grandTotal = totalProductPrice + delivery;
  return (<>
  <Banner/>
    <Container maxWidth="md" sx={{ textAlign: 'center', mt: 10 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: '16px' }}>
        <Box display="flex" justifyContent="center" sx={{ mb: 3 }}>
          <CheckCircleOutlineIcon sx={{ fontSize: 80, color: 'green' }} />
        </Box>
        <Typography variant="h4" gutterBottom>
          Thank you for your order!
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Your order has been successfully placed. You’ll receive a confirmation email soon.
        </Typography>

        <Divider sx={{ my: 3 }} />

        {/* Customer Information */}
        <Box textAlign="left" sx={{ mb: 3 }}>
          <Typography variant="h6">Order Summary</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            <strong>Customer:</strong> {firstName} {lastName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Email:</strong> {email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Phone:</strong> {phone}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Shipping Address:</strong> {address.apartment}, {address.floor}, {address.street}, {address.building}, {address.city}, {address.postalCode}
          </Typography>
        </Box>

        {/* Product List */}
        <List>
          {products.map((item) => (
            <ListItem key={item._id} sx={{ mb: 2, p: 2, border: '1px solid #eee', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
              <ListItemAvatar>
                <Avatar src={item.product.images[0]} variant="square" sx={{ width: 80, height: 80 }} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h6">{item.product.name}</Typography>}
                secondary={
                  <>
                   
                    <Typography component="span" variant="body2" color="text.primary" sx={{ display: 'block', mt: 1 ,mb:1}}>
                      Quantity: {item.quantity}
                    </Typography>
                    <Typography component="span" variant="body2" color="text.primary">
                      Price: {item.product.variant.price} EGP
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>

        <Divider sx={{ my: 3 }} />

        {/* Total and Actions */}
        <Typography variant="body1" sx={{ mb: 2 }}>
    <strong>Products Total:</strong> {totalProductPrice.toLocaleString()} EGP
  </Typography>
  <Typography variant="body1" sx={{ mb: 2 }}>
    <strong>Delivery: </strong>{delivery} EGP
  </Typography>
  <Typography variant="body1" sx={{ mb: 2 }}>
    <strong> Total: </strong> {grandTotal.toLocaleString()} EGP
  </Typography>

        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
          
          <Button
            variant="outlined"
            color="primary"
            sx={{ borderRadius: '8px', py: 1, px: 3 }}
            onClick={() => navigate("/shop")}
          >
            Continue Shopping
          </Button>
        </Box>
      </Paper>
    </Container></>
  );
};

export default OrderConfirmation;
