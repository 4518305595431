import React, { useEffect, useState } from "react";
import "../../styles/header/navItems.css";
import logo from "../../assets/logo/WORDMARK LOGO-01.png";
import { Link, useHref, useNavigate, useNavigation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faHeart } from "@fortawesome/free-solid-svg-icons";
import axios from "../../axios/axios";
import { useWishlist } from "../../WishlistContext";
import { useCart } from "../../CartContext";
import { useGuestCart } from "../../GuestCartContext";

export default function Navbar() {
  const language = sessionStorage.getItem("lang") || "en";

  const [wishlistData, setWishlistData] = useState([]);
  const [cartData, setCartData] = useState([]);

  const { wishlistCount } = useWishlist();
  const { cartCount } = useCart();
  const { guestCartCount } = useGuestCart();
  const [currentPage, setCurrentPage] = useState(1);
  const [NoOfPages, setTotalPages] = useState(1);
  const perPage = 6;
  const displayPages = 5;
  const halfDisplay = Math.floor(displayPages / 2);
  let startPage = Math.max(1, currentPage - halfDisplay);
  let endPage = Math.min(NoOfPages, startPage + displayPages - 1);
  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1);
  }
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    const fetchWishlistData = async () => {
      try {
        const response = await axios.get(
          `/users/wishlist?page=${currentPage}&perPage=${perPage}`,
          {
            headers: { Authorization: sessionStorage.getItem("token") },
          }
        );
        setWishlistData(response.data.wishlist);
        // console.log(response.data)
        setTotalPages(response.data.pages);
      } catch (error) {
        // console.log(error)
      }
    };
    const fetchCartData = async () => {
      try {
        const response = await axios.get("/users/cart", {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setCartData(response.data.cart);
        //  console.log(response.data)
      } catch (error) {
        // console.log(error);
      }
    };
    fetchWishlistData();
    fetchCartData();
  }, []);

  let href = useHref();

  // console.log(href);
  useEffect(() => {
    document.addEventListener("click", function (event) {
      let isClickInsideNavbar = document
        .querySelector(".navbar")
        .contains(event.target);
      let isMobileViewport = window.innerWidth <= 768;
      if (!isClickInsideNavbar && isMobileViewport) {
        let navbarToggler = document.querySelector(".navbar-toggler");
        if (navbarToggler.getAttribute("aria-expanded") === "true") {
          navbarToggler.click();
        }
      }
    });

    document.querySelectorAll(".navbar-nav .nav-item").forEach(function (link) {
      link.addEventListener("click", function () {
        let isMobileViewport = window.innerWidth <= 768;
        if (isMobileViewport) {
          let navbarToggler = document.querySelector(".navbar-toggler");
          if (navbarToggler.getAttribute("aria-expanded") === "true") {
            navbarToggler.click();
          }
        }
      });
    });
  }, []);

  let navigate = useNavigate();

  const token = sessionStorage.getItem("token");

  async function logout() {
    try {
      await axios.delete("/users/logout", {
        headers: {
          Authorization: `${token}`,
        },
      });
      sessionStorage.clear();
      navigate("/");
      // console.log("Logout successful");
      window.location.reload();
    } catch (error) {
      sessionStorage.clear();
      console.error("Logout failed:", error.message);
    }
  }

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-white shadow mb-5 fixed-top mt-5"
        dir={language === "ar" ? "rtl" : "ltr"}
      >
        <div className="container-fluid ps-0">
          <Link className="navbar-brand" to={"/"}>
            <img src={logo} alt="logo" width={190} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className={`navbar-nav ps-5 mx-auto ${language === "en" ? "me-md-5" : "ms-md-5"
                }  me-0 mb-2 mb-lg-0 d-flex  justify-content-between navbar-size`}
            >
              <li className="nav-item">
                <Link
                  className={
                    href == "/"
                      ? "nav-link text-capitalize fw-bold navlight"
                      : "nav-link fw-bold text-capitalize"
                  }
                  to={"/"}
                >
                  {language === "ar" ? "الرئيسية" : "Home"}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    href == "/shop"
                      ? "nav-link text-capitalize fw-bold navlight"
                      : "nav-link fw-bold text-capitalize"
                  }
                  to={"/shop"}
                >
                  {language === "ar" ? "تسوق" : "Shop"}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    href == "/blog"
                      ? "nav-link text-capitalize fw-bold navlight"
                      : "nav-link fw-bold text-capitalize"
                  }
                  to={"/blog"}
                >
                  {language === "ar" ? "المدونات" : "Blog"}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    href == "/about"
                      ? "nav-link text-capitalize fw-bold navlight"
                      : "nav-link fw-bold text-capitalize"
                  }
                  to={"/about"}
                >
                  {language === "ar" ? "اقرأ عنا" : "About"}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    href == "/contact"
                      ? "nav-link text-capitalize fw-bold navlight"
                      : "nav-link fw-bold text-capitalize"
                  }
                  to={"/contact"}
                >
                  {language === "ar" ? "تواصل معنا" : "Contact"}
                </Link>
              </li>
            </ul>
            {token ? (
              <ul className="navbar-nav ms-md-auto mb-2 mb-lg-0 me-md-4 ms-md-0 ms-3 iconsspace d-flex justify-content-end gap-3">
                <li className="nav-item pe-md-0 pe-3">
                  <Link to={"/profile"}>
                    <i className="fa-regular fa-circle-user icon"></i>
                  </Link>
                </li>

                <li className="nav-item pe-md-0 pe-3 test">
                  <Link to={"/wishlist"}>
                    <i className="fa-regular fa-heart icon"></i>
                    {wishlistData?.length > 0 ? (
                      <span className="wishlist-count">{wishlistCount}</span>
                    ) : (
                      <span className="wishlist-count">0</span>
                    )}
                  </Link>
                </li>
                <li className="nav-item pe-md-0 pe-3 testCart">
                  <Link to={"/cart"}>
                    <i className="fa-solid fa-basket-shopping icon"></i>
                    {cartCount ? (
                      <span className="cart-count">{cartCount}</span>
                    ) : (
                      <span className="cart-count">0</span>
                    )}
                  </Link>
                </li>
                <li className="nav-item pe-md-0 pe-3" onClick={logout}>
                  <i className="fa-solid fa-arrow-right-from-bracket icon"></i>
                </li>
              </ul>
            ) : (
              <ul
                className={`navbar-nav ${language === "en" ? "ms-auto" : "me-auto"
                  }  mb-2 mb-lg-0 me-md-4 me-0 iconsspace d-flex justify-content-end gap-3`}
              >
                <li className="nav-item pe-md-0 pe-3 testCart">
                  <Link to={"/cart"}>
                    <i className="fa-solid fa-basket-shopping icon"></i>
                      <span className="cart-count">{guestCartCount}</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/login"}>
                    <i className="fa-regular fa-circle-user icon"></i>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
