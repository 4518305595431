import { useEffect, useState } from "react";
import CartHeader from "../components/Header/CartHeader";
import ShoppingCart from "../components/Cart/ShoppingCart";
import { Col, Row, Form } from "react-bootstrap";
import axios from "../axios/axios";
import { useGuestCart } from "../GuestCartContext";

export default function Cart() {
  const language = sessionStorage.getItem("lang") || "en";

  const [wishlistData, setWishlistData] = useState([]);
  const [cartTotal, setCartTotal] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { guestCartData } = useGuestCart();

  useEffect(() => {
    const fetchWishlistData = async () => {
      try {
        const response = await axios.get("/users/cart", {
          headers: { Authorization: sessionStorage.getItem("token") },
        });
        setWishlistData(response.data.cart);
        setCartTotal(response.data);

        // console.log(response.data.cart);

        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    if (sessionStorage.getItem("token")) fetchWishlistData();
    else {
      setLoading(false);
      setWishlistData(
        guestCartData.map((one) => ({ product: one, quantity: one.quantity }))
      );
    }
  }, [guestCartData]);
  const columns = [
    {
      Header: "",
      accessor: "product._id",
      Cell: ({ row }) => (
        <button
          onClick={() =>
            handleRemoveFromWishlist(
              row.original.product._id,
              row.original.product.variant._id
            )
          }
        >
          X
        </button>
      ),
    },
    {
      Header: "Image",
      accessor: (row) => {
        const imageUrl = row.product?.images?.[0]; // Using optional chaining operator
        // console.log(imageUrl); // Log the value
        return imageUrl ? (
          <img
            src={imageUrl}
            alt="Product"
            style={{
              maxHeight: "340px",
              minHeight: "340px",
              borderRadius: "1rem",
            }}
          />
        ) : null;
      },
    },
    {
      Header: "Name",
      accessor: "product.name",
    },
    {
      Header: "Price",
      accessor: "product.variant.price",
    },
  ];
  const handleRemoveFromWishlist = async (productId, variantId) => {
    try {
      await axios.delete(`/users/removeFromWishlist/${productId}/${variantId}`);

      // console.log('Product removed from wishlist successfully');
    } catch (error) {
      console.error("Error removing product from wishlist:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <CartHeader />

      {loading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            width: "100%",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <div className="typing-indicator">
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-circle"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
            <div className="typing-shadow"></div>
          </div>
        </div>
      ) : (
        <>
          {wishlistData.length > 0 ? (
            <>
              <ShoppingCart
                data={wishlistData}
                columns={columns}
                cartTotal={cartTotal}
              />
            </>
          ) : (
            <h1
              className="fw-bold my-5 text-center"
              {...(language === "ar" ? { dir: "rtl", lang: "ar" } : {})}
            >
              {" "}
              {language === "ar" ? "عربة التسوق فارغة." : "Your cart is empty."}
            </h1>
          )}
        </>
      )}
    </>
  );
}
