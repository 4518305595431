import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import ReactPixel from 'react-facebook-pixel';
import TopHeader from "./components/headers/TopHeader";
import Home from "./pages/home.jsx";
import About from "./pages/About";
import Blog from "./pages/Blog.jsx";
import SingleBlog from "./components/Blogs/SingleBlog.jsx";
import Login from "./pages/Login.jsx";
import Register from "./pages/Register.jsx";
import Contact from "./pages/Contact.jsx";
import Cart from "./pages/Cart.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Shop from "./pages/Shop.jsx";
import SingleProduct from "./components/Shop/SingleProduct.jsx";
import Verify from "./components/Auth/Verify.jsx";
import Wishlist from "./pages/Wishlist";
import Profile from "./pages/Profile.jsx";
import Navbar from "./components/headers/Navbar.jsx";
import Forget from "./pages/Forget.jsx";
import Privacy from "./pages/Privacy.jsx";
import NotFound from "./pages/NotFound.jsx";
import OrderConfirmation from "./pages/OrderConfirmation.jsx";
import UseMetaPixel from "./pages/UseMetaPixel.jsx";

// Function to generate a unique guestId
function generateGuestId() {
  return (
    "guest_" +
    new Date().getTime() +
    "_" +
    Math.random().toString(36).substr(2, 9)
  );
}

function App() {
  useEffect(() => {
    // Check if guestId exists in cookies
    let storedGuestId = Cookies.get("guestId");
    if (!storedGuestId) {
      // If guestId does not exist, generate a new one and set it in the cookie
      storedGuestId = generateGuestId();
      Cookies.set("guestId", storedGuestId, { expires: 36500, path: "/" }); // Cookie lasts 7 days
    }
  }, []);


//   const pixelId = '892071336225131';

//  let connect =  UseMetaPixel(pixelId);

//  console.log("connect" , connect);


  ReactPixel.init("892071336225131")
  ReactPixel.pageView()


  return (
    <>
      <TopHeader />
      <Navbar />
      <Routes>
        {/* Login */}
        <Route path="/" element={<Home />} />
        <Route path="/orderConfirmation" element={<OrderConfirmation />} />

        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/singleBlog/:id" element={<SingleBlog />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/singleProduct/:id" element={<SingleProduct />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/forget/:token" element={<Forget />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
