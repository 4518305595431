import React , {useEffect}from 'react'
import ShopHeader from '../components/Header/ShopHeader'
import ShopProducts from '../components/Shop/ShopProducts'
import { useLocation } from 'react-router-dom';

export default function Shop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  return (
    <>
    <ShopHeader/>
    <ShopProducts/>
    
    </>
  )
}
